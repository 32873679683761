import { navigate, PageProps } from "gatsby"
import { useAtom } from "jotai"
import React, { ReactElement, useCallback, useEffect } from "react"
import MediaQuery from "react-responsive"
import { LatLng } from "../__generated__/proto/google/type/latlng"
import { Parking } from "../__generated__/proto/itech/motorist/pksha/v1/parking"
import { parkingNameAtom } from "../atoms/atom"
import {
  Layout,
  SEO,
  Map,
  SearchResultList,
  SearchResultPagingRow,
  AreaBreadcrumbsRow,
  ParkingDetailInfoTable,
} from "../components"
import * as config from "../config"
import { ParkingService } from "../domain/services/parking-service"
import { GetParkingUseCase, ListParkingsUseCase } from "../domain/use-cases"
import { useAuth } from "../middleware/AuthGuard"
import {
  ParkingDetailReducerProvider,
  useParkingDetailReducer,
} from "./parking-detail-reducer"
import * as styles from "./parking-detail.module.scss"
// MARK: - Use Cases

const parkingService = new ParkingService()
const getParkingUseCase = new GetParkingUseCase(parkingService)
const listParkingsUseCase = new ListParkingsUseCase(parkingService)

// MARK React Element

interface PageContext {
  parking: Parking
  prefecture?: string
  city?: string
  town?: string
}

interface LocationState {
  searchPagePath?: string
}

const ParkingDetail = (
  props: PageProps<void, PageContext, LocationState>
): ReactElement => {
  // MARK: - Variales

  const initialParking = props.pageContext.parking
  const initialMapCenter = initialParking.latLng
  const prefecture = props.pageContext.prefecture
  const city = props.pageContext.city
  const town = props.pageContext.town
  const searchPagePath = props.location.state?.searchPagePath

  // MARK: - States

  const [, setParkingName] = useAtom(parkingNameAtom)
  const { user, logout } = useAuth()
  const [state, dispatch] = useParkingDetailReducer()
  const { currentParking, parkings, currentPage, mapCenter } = state
  // MARK: - Callbacks

  const setMapCenter = useCallback((mapCenter: LatLng) => {
    dispatch({
      type: "SET_MAP_CENTER",
      mapCenter,
    })
  }, [])

  const setCurrentPage = useCallback((currentPage: number) => {
    dispatch({ type: "SET_CURRENT_PAGE", currentPage })
  }, [])

  // MARK: - Effects

  useEffect(() => {
    dispatch({ type: "SET_CURRENT_PARKING", parking: initialParking })

    if (initialMapCenter) {
      dispatch({ type: "SET_MAP_CENTER", mapCenter: initialMapCenter })
    }

    getParkingUseCase.call({ name: initialParking.name }).then(parking => {
      dispatch({
        type: "SET_CURRENT_PARKING",
        parking: parking ?? initialParking,
      })
    })
  }, [])

  // MARK: - Methods

  const loadParkingsAround = useCallback(
    (mapBounds: google.maps.LatLngBounds) => {
      const ne = mapBounds.getNorthEast()
      const sw = mapBounds.getSouthWest()

      listParkingsUseCase
        .call({
          pageSize: 100,
          boundingBox: {
            high: { latitude: ne.lat(), longitude: ne.lng() },
            low: { latitude: sw.lat(), longitude: sw.lng() },
          },
        })
        .then(response => {
          dispatch({ type: "SET_PARKINGS", parkings: response.parkings })
        })
    },
    []
  )

  const checkPayable = (paymentMethod: string): boolean => {
    return paymentMethod === "CREDIT_CARD" || paymentMethod === "WITHDRAWAL"
  }

  const handleReservationClick = useCallback(() => {
    if (!user) {
      setParkingName(initialParking.name)
      navigate("/signin")
    } else if (user && !checkPayable(user.paymentMethod)) {
      alert(
        "クレジットカードを登録していないのでご利用できません。\n\n会員情報照会画面にて登録してください"
      )
      logout()
      navigate("https://qt-net.itcbb.net/settings/credit-card")
    } else {
      navigate(`/${initialParking.name}/reservation`)
    }
  }, [initialParking, user])

  const navigateToParkingDetail = useCallback((parking: Parking) => {
    navigate(`/${parking.name}`, {
      state: {
        searchPagePath: props.location.pathname + props.location.search,
      },
    })
  }, [])

  // MARK: - Render

  if (currentParking === null) {
    return <></>
  }

  return (
    <Layout currentParking={currentParking}>
      <SEO
        title={currentParking.displayName}
        description={
          currentParking &&
          `${currentParking.displayName}（${
            currentParking.fullAddress
          }）の情報です。車室数は${
            currentParking.availability?.totalSpaceCount ?? "-"
          }台です。`
        }
        lang="ja"
      />
      <div className={styles.mainContainer}>
        <MediaQuery minWidth={config.RESPONSIVE_BREAKPOINT}>
          <button
            className={styles.backToTopButton}
            onClick={() => navigate(searchPagePath ?? "/")}
          >
            検索画面に戻る
          </button>
        </MediaQuery>

        {prefecture && (
          <AreaBreadcrumbsRow
            prefecture={prefecture}
            city={city}
            town={town}
            parkingName={currentParking.displayName}
          />
        )}
        <div className={styles.parkingNameContainer}>
          <p className={styles.parkingNameText}>{currentParking.displayName}</p>
          <button
            onClick={handleReservationClick}
            className={styles.reservationPageButton}
          >
            予約手続きへ
          </button>
        </div>

        <div className={styles.map}>
          <div className={styles.mapPlaceholder}>
            <Map
              width="100%"
              height="100%"
              center={initialMapCenter ?? null}
              zoom={config.DEFAULT_MAP_ZOOM}
              showCenterMarker={true}
              onCenterChanged={setMapCenter}
              onBoundsLoaded={loadParkingsAround}
            />
          </div>
        </div>
        <div className={styles.infoContainer}>
          <p className={styles.infoText}>
            ※表示情報が一部異なる場合がございます
          </p>
        </div>
        <ParkingDetailInfoTable parking={currentParking} zipcode={null} />

        <div className={styles.parkingListContainer}>
          <p className={styles.parkingListTitle}>
            {currentParking.displayName}周辺の駐車場
          </p>
          <SearchResultList
            parkings={parkings}
            currentPage={currentPage}
            mapCenter={mapCenter}
            onClickItem={navigateToParkingDetail}
            onClickParkingName={navigateToParkingDetail}
          />
          <SearchResultPagingRow
            allParkingCount={parkings.length}
            currentPage={currentPage}
            orderType="DISTANCE"
            showOrderSelection={false}
            onSelectPage={setCurrentPage}
          />
        </div>
      </div>
    </Layout>
  )
}

const ParkingDetailInProvider = (
  props: PageProps<void, PageContext, LocationState>
): ReactElement => {
  return (
    <ParkingDetailReducerProvider>
      <ParkingDetail {...props} />
    </ParkingDetailReducerProvider>
  )
}

export default ParkingDetailInProvider
